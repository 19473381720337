import { DownOutlined, FileTextOutlined } from "@ant-design/icons";
import axios from "axios";
import countries from "i18n-iso-countries";
import enLocale from "i18n-iso-countries/langs/en.json";
import React, { useRef, useState } from "react";
import useCollapse from "react-collapsed";
import { useTranslation } from "react-i18next";
import { GetAccessCodeResult } from "../../utils/types";
import "./RegformStyle.css";
import { isDateOfBirthValid, isEmailValid } from "../../utils/validation";

const CollapsibleGuests = ({
  i,
  j,
  bookingItems,
  setBookingItems,
  isRequiredGuestProperties
}: {
  i: any;
  j: any;
  bookingItems: any;
  setBookingItems: any;
  isRequiredGuestProperties: IsRequiredGuestProperty;
}) => {
  const addressEl = useRef<HTMLInputElement>(null);
  const postalCodeEl = useRef<HTMLInputElement>(null);
  const cityEl = useRef<HTMLInputElement>(null);

  const { t } = useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "" });

  const guest = bookingItems[i].guests[j];
  const show = bookingItems[i].show[0];
  const isFamilyMember = bookingItems[i].guests[j].isFamilyMember;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  const changeFormFieldValue = (e: any, source: string) => {
    const _value = e.target.value;
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[j][source] = _value;
    setBookingItems(_bookingItemsCopy);
  };

  const changeIsFamilyFieldValue = (e: any, source: string) => {
    const _isFamilyMember = e.target.checked;
    const _bookingItemsCopy = [...bookingItems];
    _bookingItemsCopy[i].guests[j][source] = _isFamilyMember;
    addressEl?.current && setInputValidationClass(addressEl.current, true);
    postalCodeEl?.current && setInputValidationClass(postalCodeEl.current, true);
    cityEl?.current && setInputValidationClass(cityEl.current, true);
    if (_isFamilyMember) {
      _bookingItemsCopy[i].guests[j]["address"] = "";
      _bookingItemsCopy[i].guests[j]["postalCode"] = "";
      _bookingItemsCopy[i].guests[j]["city"] = "";
    }
    setBookingItems(_bookingItemsCopy);
  };

  const validateForm = (e: any, source: string) => {
    setRequiredInputValidationClass(e);
    if ("email" === source) setValidationClassForEmail(e);
    if ("dateofbirth" === source) setValidationClassForDateOfBirth(e);
  };

  const setInputValidationClass = (e: Element, isValid: boolean) => {
    if (isValid) e.classList.remove("propertyForm-invalid");
    else e.classList.add("propertyForm-invalid");
  };

  const setRequiredInputValidationClass = (e: any) => {
    const _input = e.target;
    const _isValid = !(_input.required && _input.value.trim() === "");
    setInputValidationClass(_input, _isValid);
  };

  const setValidationClassForEmail = (e: any) => {
    const _input = e.target;
    const _isValid = isEmailValid(_input.value);
    setInputValidationClass(_input, _isValid);
  };

  const setValidationClassForDateOfBirth = (e: any) => {
    const _input = e.target;
    const _isValid = isDateOfBirthValid(_input.value);
    setInputValidationClass(_input, _isValid);
  };

  return (
    <div className="collapsible">
      <div className="form-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "" : <FileTextOutlined style={{ fontSize: 30 }} />}
        {isExpanded ? <DownOutlined className="arrow" /> : ""}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <form id="guest-reg-form" className="regformWrapper">
            <span className="inline">
              <label>
                <p>
                  {t("registration.fname")}
                  {isRequiredGuestProperties.firstName && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredGuestProperties.firstName}
                  placeholder={t("registration.fname")}
                  value={guest.firstName}
                  onChange={e => changeFormFieldValue(e, "firstName")}
                  onBlur={e => validateForm(e, "firstName")}
                />
              </label>
              <label>
                <p>
                  {t("registration.lname")}
                  {isRequiredGuestProperties.lastName && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  required={isRequiredGuestProperties.lastName}
                  placeholder={t("registration.lname")}
                  value={guest.lastName}
                  onChange={e => changeFormFieldValue(e, "lastName")}
                  onBlur={e => validateForm(e, "lastName")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.dateofbirth")}
                  {isRequiredGuestProperties.dateOfBirth && "*"}
                </p>
                <input
                  className="inputField"
                  type="date"
                  required={isRequiredGuestProperties.dateOfBirth}
                  placeholder={t("registration.dateofbirth")}
                  value={guest.dateOfBirth}
                  onChange={e => changeFormFieldValue(e, "dateOfBirth")}
                  onBlur={e => validateForm(e, "dateOfBirth")}
                />
              </label>
              <label>
                <p>
                  {t("registration.placeofbirth")}
                  {isRequiredGuestProperties.placeOfBirth && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.placeOfBirth}
                  required={isRequiredGuestProperties.placeOfBirth}
                  onChange={e => changeFormFieldValue(e, "placeOfBirth")}
                  onBlur={e => validateForm(e, "placeOfBirth")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.gender")}
                  {isRequiredGuestProperties.gender && "*"}
                </p>
                <select
                  className="dropDown"
                  name="gender"
                  value={guest.gender}
                  required={isRequiredGuestProperties.gender}
                  onChange={e => changeFormFieldValue(e, "gender")}
                  onBlur={e => validateForm(e, "gender")}
                >
                  <option value="">{t("registration.choosegender")}</option>
                  <option value="Male">{t("registration.male")}</option>
                  <option value="Female">{t("registration.female")}</option>
                  <option value="Other">{t("registration.other")}</option>
                </select>
              </label>

              <label>
                <p>
                  {t("registration.nationality")}
                  {isRequiredGuestProperties.nationality && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.nationality}
                  required={isRequiredGuestProperties.nationality}
                  onChange={e => changeFormFieldValue(e, "nationality")}
                  onBlur={e => validateForm(e, "nationality")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            {j !== 0 ? (
              <label>
                <p>{t("registration.familyMember")}</p>
                <div className="inputField-familyMember-wrapper">
                  <input
                    type="checkbox"
                    id="familyMember"
                    name="familyMember"
                    onChange={e => changeIsFamilyFieldValue(e, "isFamilyMember")}
                    checked={guest.isFamilyMember}
                  ></input>
                </div>
              </label>
            ) : (
              ""
            )}
            <span className="inline">
              <label>
                <p>
                  {t("registration.street")}
                  {isRequiredGuestProperties.address && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.street")}
                  value={guest.address}
                  required={isRequiredGuestProperties.address}
                  ref={addressEl}
                  onChange={e => changeFormFieldValue(e, "address")}
                  onBlur={e => validateForm(e, "address")}
                />
              </label>
              <label>
                <p>
                  {t("registration.postalcode")}
                  {isRequiredGuestProperties.postalCode && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.postalcode")}
                  value={guest.postalCode}
                  required={isRequiredGuestProperties.postalCode}
                  ref={postalCodeEl}
                  onChange={e => changeFormFieldValue(e, "postalCode")}
                  onBlur={e => validateForm(e, "postalCode")}
                />
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.city")}
                  {isRequiredGuestProperties.city && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  disabled={isFamilyMember}
                  placeholder={t("registration.city")}
                  value={guest.city}
                  required={isRequiredGuestProperties.city}
                  ref={cityEl}
                  onChange={e => changeFormFieldValue(e, "city")}
                  onBlur={e => validateForm(e, "city")}
                />
              </label>
              <label>
                <p>
                  {t("registration.country")}
                  {isRequiredGuestProperties.country && "*"}
                </p>
                <select
                  className="dropDown"
                  value={guest.country}
                  required={isRequiredGuestProperties.country}
                  onChange={e => changeFormFieldValue(e, "country")}
                  onBlur={e => validateForm(e, "country")}
                >
                  {!!countryArr?.length &&
                    countryArr.map(({ label, value }: any) => (
                      <option key={value} value={value}>
                        {label}
                      </option>
                    ))}
                </select>
              </label>
            </span>
            <span className="inline">
              <label>
                <p>
                  {t("registration.id")}
                  {isRequiredGuestProperties.typeOfId && "*"}
                </p>
                <select
                  className="dropDown"
                  name="typeOfID"
                  value={guest.typeOfID}
                  required={isRequiredGuestProperties.typeOfId}
                  onChange={e => changeFormFieldValue(e, "typeOfID")}
                  onBlur={e => validateForm(e, "typeOfID")}
                >
                  <option value="">{t("registration.Def")}</option>
                  <option value="idNumber">{t("registration.idNumberOpt")}</option>
                  <option value="passport">{t("registration.passportOpt")}</option>
                  <option value="drivingLicense">{t("registration.drivingLicenseOpt")}</option>
                </select>
              </label>

              <label>
                <p>
                  {t("registration.passport")}
                  {isRequiredGuestProperties.idNumber && "*"}
                </p>
                <input
                  className="inputField"
                  type="text"
                  placeholder={t("registration.passport")}
                  value={guest.idNumber}
                  required={isRequiredGuestProperties.idNumber}
                  onChange={e => changeFormFieldValue(e, "idNumber")}
                  onBlur={e => validateForm(e, "idNumber")}
                />
              </label>
            </span>
            <label>
              <p>
                {t("registration.email")}
                {isRequiredGuestProperties.email && "*"}
              </p>
              <input
                className="inputField-email"
                type="email"
                placeholder={t("registration.email")}
                value={guest.email}
                required={isRequiredGuestProperties.email}
                onChange={e => changeFormFieldValue(e, "email")}
                onBlur={e => validateForm(e, "email")}
              />
            </label>
          </form>
        </div>
      </div>
    </div>
  );
};

const CollapsibleAccessCode = ({ propertyname, bookingId, setAccessCodes, accessCodes, backendUrl }: any) => {
  const [accessModuleState, setAccessModuleState] = useState<boolean>();
  const [isLoading, setIsLoading] = useState(false);

  const expand = () => {
    setAccessModuleState(true);
  };

  const collapse = () => {
    setAccessModuleState(false);
  };

  const handleClick = async () => {
    await callGetAccessCode();
    if (accessModuleState) {
      expand();
    } else {
      collapse();
    }
  };

  const callGetAccessCode = async () => {
    try {
      setTimeout(() => {
        setIsLoading(true);
      }, 0);

      const config = {
        method: "post",
        url: `${backendUrl}getAccessCode`,
        headers: { "Content-Type": "application/json" },
        data: {
          bookingId
        }
      };
      const response = await axios(config);
      const getAccessCodeResult: GetAccessCodeResult = response.data;
      if (getAccessCodeResult.severity === "normal") {
        const newAccessCodes = new Map(accessCodes);
        newAccessCodes.set(bookingId, getAccessCodeResult.accessCode!);
        setAccessCodes(newAccessCodes);
      } else if (getAccessCodeResult.severity === "error") {
        alert(`Error: ${getAccessCodeResult.error?.toString()}`);
      } else if (getAccessCodeResult.severity === "warning") {
        alert(`Warning: ${getAccessCodeResult.error}`);
      } else {
        console.error("should not be possible, there is a problem");
        alert(getAccessCodeResult);
      }
    } catch (error: any) {
      console.log(error);
      alert(`There was an error on our end, please contact support. Messsage: ${error.message}`);
    } finally {
      setIsLoading(false); // Disable loading when done
    }
  };

  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps({ onClick: handleClick })}>
        {isExpanded ? "Hide Entry Code" : ""}
        {isExpanded ? "" : "Entry Code"}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        {isLoading && (
          <div className="loading-overlay">
            <p className="loading-text">Loading...</p>
          </div>
        )}
        <div className="content">
          <p className="dashboard-guest-loxone-data">The access code for entering apartment</p>
          <p className="dashboard-guest-loxone-data">
            {propertyname} Entry Code: {accessCodes.get(bookingId)}
          </p>
        </div>
      </div>
    </div>
  );
};

const CollapsibleLoxoneCredentials = ({ url, username, password }: any) => {
  useTranslation();
  countries.registerLocale(enLocale);

  const countryArr = Object.entries(countries.getNames("en", { select: "official" })).map(([key, value]) => ({
    label: value,
    value: key
  }));
  countryArr.unshift({ label: "Choose a country", value: "CHOOSE_COUNTRY" });

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse();

  return (
    <div className="control-apartment-collapsible">
      <div className="control-apartment-collapsed-background" {...getToggleProps()}>
        {isExpanded ? "Hide Information" : ""}
        {isExpanded ? "" : "Control Apartment"}
      </div>
      <div className="collapse-open" {...getCollapseProps()}>
        <div className="content">
          <p className="dashboard-guest-loxone-data">Control your apartment heating, lighting and ...</p>
          <p className="dashboard-guest-loxone-data">
            Dashboard:{" "}
            <a href={url} target="_blank" rel="noreferrer">
              Application URL
            </a>
          </p>
          <div className="loxone-credentials-grid">
            <p className="dashboard-guest-loxone-data">Username: {username}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(username);
              }}
            >
              Copy
            </button>
            <p className="dashboard-guest-loxone-data">Password: {password}</p>
            <button
              className="loxone-credentials-copy-button"
              onClick={() => {
                navigator.clipboard.writeText(password);
              }}
            >
              Copy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export { CollapsibleGuests, CollapsibleAccessCode, CollapsibleLoxoneCredentials };

export interface IsRequiredGuestProperty {
  firstName: boolean;
  lastName: boolean;
  dateOfBirth: boolean;
  placeOfBirth: boolean;
  gender: boolean;
  nationality: boolean;
  address: boolean;
  postalCode: boolean;
  city: boolean;
  country: boolean;
  idNumber: boolean;
  email: boolean;
  typeOfId: boolean;
}
