import { IsRequiredInvoiceProperty } from "../pages/Registration/InvoiceAddress";
import { IsRequiredGuestProperty } from "../pages/Registration/Regform";

export const isTokenForAdmin = (): boolean => {
  const token = localStorage.getItem("token");
  if (token == null) return false;
  return token !== "undefined";
};

export const isEmailValid = (email: string): boolean => {
  const emailRegex =
    // eslint-disable-next-line no-control-regex
    /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
  if (!email) return false;
  if (email.length > 254) return false;
  const valid = emailRegex.test(email);
  if (!valid) return false;
  const parts = email.split("@");
  if (parts[0].length > 64) return false;
  const domainParts = parts[1].split(".");
  if (domainParts.some((part: string) => part.length > 63)) return false;
  return true;
};

export const isEmptyValue = (value: string): boolean => {
  return value.trim() === "";
};

export const isGenderValid = (value: string): boolean => {
  return value === "Female" || value === "Male" || value === "Other";
};

export const isTypeOfIdValid = (value: string): boolean => {
  return value === "idNumber" || value === "passport" || value === "drivingLicense";
};

export const isDateOfBirthValid = (value: string): boolean => {
  try {
    const d = Date.parse(value);
    if (isNaN(d)) return false;
    const _value = new Date(value);
    const _minDate = new Date("1900-01-01");
    const _maxDate = new Date();
    _maxDate.setDate(_maxDate.getDate() - 1);
    return _value >= _minDate && _value <= _maxDate;
  } catch (any) {
    return false;
  }
};

export const isCountryValid = (value: string): boolean => {
  if (value.length !== 2) return false;
  if (value.toUpperCase() !== value) return false;
  return true;
};

export const getIsRequiredInvoiceProperties = (): IsRequiredInvoiceProperty => {
  return {
    companyName: false,
    firstName: true,
    lastName: true,
    taxNumber: false,
    address: true,
    postalCode: true,
    city: true,
    country: true
  };
};

export const getIsRequiredGuestProperties = (techId: string, guestIndex: number): IsRequiredGuestProperty => {
  return techId === "1301" || techId === "091"
    ? _getIsRequiredGuestPropertiesForDe(guestIndex)
    : _getIsRequiredGuestPropertiesForHun();
};

const _getIsRequiredGuestPropertiesForDe = (index: number): IsRequiredGuestProperty => {
  return index === 0
    ? {
        firstName: true,
        lastName: true,
        dateOfBirth: true,
        placeOfBirth: true,
        gender: true,
        nationality: true,
        address: true,
        postalCode: true,
        city: true,
        country: true,
        idNumber: true,
        email: true,
        typeOfId: true
      }
    : {
        firstName: true,
        lastName: false,
        dateOfBirth: true,
        placeOfBirth: false,
        gender: false,
        nationality: false,
        address: false,
        postalCode: false,
        city: false,
        country: true,
        idNumber: false,
        email: false,
        typeOfId: false
      };
};

const _getIsRequiredGuestPropertiesForHun = (): IsRequiredGuestProperty => {
  return {
    firstName: true,
    lastName: true,
    dateOfBirth: true,
    placeOfBirth: true,
    gender: true,
    nationality: true,
    address: true,
    postalCode: true,
    city: true,
    country: true,
    idNumber: true,
    email: true,
    typeOfId: true
  };
};
